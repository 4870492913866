* {
  margin: 0;
  padding: 0;
}
html,
body {
  font-family: "Jura", sans-serif;
  font-size: 1rem;
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.wrap {
  flex: 1 0 auto;
  width: 92%;
  margin: 0 auto;
  margin-top: 5em;
}
@media only screen and (min-width: 1024px) {
  .wrap {
    width: 1024px;
  }
}
a {
  color: #b6262c;
  text-decoration: none;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}
a:visitied,
a:hover,
a:active {
  text-decoration: none;
}
::selection {
  background-color: #b6262c;
  color: white;
}
::-moz-selection {
  background-color: #b6262c;
  color: white;
}
.footer {
  flex-shrink: 0;
  width: 92%;
  height: 80px;
  box-sizing: border-box;
  color: #4f515d;
  font-size: 0.8rem;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  padding-top: 18px;
  padding-bottom: 18px;
  border: 0;
  border-top: 1px solid #eee;
}
@media only screen and (min-width: 1024px) {
  .footer {
    width: 1024px;
  }
}
.footer p {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.footer a:hover {
  color: #4f515d;
}
.profile {
  text-align: center;
}
.profile .portrait {
  position: relative;
  max-width: 10rem;
  margin: 0.6em auto;
}
.profile .portrait .spinner {
  width: 10rem;
  height: 10rem;
  border: 0.5em solid #3d3d3d;
  border-right-color: #b6262c;
  border-bottom-color: #b6262c;
  border-radius: 50%;
  -webkit-transition: transform 0.8s ease-in-out;
  -moz-transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}
.profile .portrait:hover .spinner {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.profile .portrait .img {
  position: absolute;
  width: 100%;
  top: 0.5em;
  bottom: 0.5em;
  left: 0.5em;
  right: 0;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.profile .name {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 2rem;
  margin: 0.2em auto;
}
.profile .title {
  font-size: 1rem;
  text-transform: uppercase;
  color: #b6262c;
  margin: 0.8em auto;
}
.profile .bio {
  font-size: 0.9rem;
}
.profile .bio p {
  margin-top: 0.9em;
}
.profile .bio a:hover {
  color: initial;
}
@media only screen and (min-width: 720px) {
  .profile .portrait {
    max-width: 11rem;
  }
  .profile .portrait .spinner {
    width: 11rem;
    height: 11rem;
  }
  .profile .name {
    font-size: 2.4rem;
  }
  .profile .title {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .profile .portrait {
    max-width: 12rem;
  }
  .profile .portrait .spinner {
    width: 12rem;
    height: 12rem;
  }
  .profile .name {
    font-size: 2.5rem;
  }
  .profile .title {
    font-size: 1.2rem;
  }
}
.contacts {
  margin: 2.2rem auto;
  text-align: center;
  font-size: 1.2rem;
}
.contacts .contact {
  display: inline-block;
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
  margin-left: 0.1em;
  margin-right: 0.1em;
  background: #f9f9fb;
  border-radius: 100%;
  color: #535358;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.contacts .contact:hover {
  background: #b6262c;
  color: #fff;
  box-shadow: 0 0 1px 7px rgba(182, 38, 44, 0.15);
}
.actions {
  margin: 3em auto;
  text-align: center;
  font-size: 1rem;
}
.actions .action {
  position: relative;
  display: inline-block;
  margin: 1.2em 1.2em;
  width: 11em;
  height: 2em;
  line-height: 2em;
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
}
.actions .action:after {
  content: "";
  transition: 0.5s all ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.actions .action:after {
  width: 100%;
  height: 2px;
  left: 50%;
  bottom: -0.3em;
  background: #d63940;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.actions .action:hover:after {
  width: 0;
}
.actions .action:hover {
  color: #666;
}
